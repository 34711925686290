/* -------------------------------------------------------------------------- */
/*                                Accordion JS                                */
/* -------------------------------------------------------------------------- */

(function($, window, document) {
    var doitonce = 0;

    window.initAccordion = function () {
        //uses classList, setAttribute, and querySelectorAll
        //if you want this to work in IE8/9 youll need to polyfill these
        var d = document,
            accordionToggles = d.querySelectorAll('.js-link-accordion'),
            setAria,
            setAccordionAria,
            switchAccordion,
            touchSupported = ('ontouchstart' in window),
            pointerSupported = ('pointerdown' in window);

        skipClickDelay = function(e){
            e.preventDefault();
            e.target.click();
        }

        setAriaAttr = function(el, ariaType, newProperty){
            el.setAttribute(ariaType, newProperty);
        };

        setAccordionAria = function(el1, el2, expanded){
            switch(expanded) {
                case "true":
                    setAriaAttr(el1, 'aria-expanded', 'true');
                    setAriaAttr(el2, 'aria-hidden', 'false');
                    break;
                case "false":
                    setAriaAttr(el1, 'aria-expanded', 'false');
                    setAriaAttr(el2, 'aria-hidden', 'true');
                    break;
                default:
                    break;
            }
        };

        //function
        switchAccordion = function(e) {
            e.preventDefault();
            if(doitonce == 0) {
                doitonce++;

                var thisQuestion = e.target;
                var thisAnswer = e.target.nextElementSibling;

                if(thisAnswer.classList.contains('is-collapsed')) {
                    setAccordionAria(thisQuestion, thisAnswer, 'true');
                } else {
                    setAccordionAria(thisQuestion, thisAnswer, 'false');
                }

                thisQuestion.classList.toggle('is-collapsed');
                thisQuestion.classList.toggle('is-expanded');

                thisAnswer.classList.toggle('is-collapsed');
                thisAnswer.classList.toggle('is-expanded');

                thisAnswer.classList.toggle('animateIn');

                setTimeout(function(){
                    doitonce = 0
                },500);
            }
        };


        for (var i=0,len=accordionToggles.length; i<len; i++) {

            if (touchSupported) {
                accordionToggles[i].addEventListener('touchstart', skipClickDelay, false);
            }

            if (pointerSupported){
                accordionToggles[i].addEventListener('pointerdown', skipClickDelay, false);
            }

            accordionToggles[i].addEventListener('click', switchAccordion, false);
        }
    }

    $(document).ready(function() {
        window.initAccordion();
        moveToAccordion();
    });

    function moveToAccordion() {
        if($('.js-accordion').length > 0) {
            if (getParameterByName('bloc')) {
                 $('html, body').animate({
                    scrollTop: ($('#' + getParameterByName('bloc')).offset().top - 100)
                }, 'slow');
            }
        }
    }

    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

})(window.jQuery, window, document);