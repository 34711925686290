/* -------------------------------------------------------------------------- */
/*                            Iframe Auto height JS                           */
/* -------------------------------------------------------------------------- */

(function($, window, document) {

  function initTimeline() {
    var $timelineEl = $('.js-timeline')

    if ($timelineEl) {
      $.each($timelineEl, function(index, element) {

        var $element     = $(element)
        var datesSlider  = $element.find('.js-dates')
        var issuesSlider = $element.find('.js-issues')
        var initialSlide = parseInt(datesSlider.data('initial-slide')) - 1
        initialSlide = initialSlide < 0 ? 0 : initialSlide

        var syncDate     = $(datesSlider)
        var syncIssues   = $(issuesSlider)

        var dateSlider = new Swiper (syncDate, {
          loop               : false,
          centeredSlides     : true,
          slidesPerView      : 'auto',
          a11y               : true,
          slideToClickedSlide: true,
          initialSlide       : initialSlide,
          onInit             : function (swiper) {
            // TEMP, DIRTY
            setTimeout(function() {
              swiper.onResize()
            }, 1)
            disableBtnIfNecessary($element, swiper)
          },
          onSlideChangeStart: function (swiper) {
            disableBtnIfNecessary($element, swiper)
          },
          breakpoints: {
            768: {
              slidesPerView : 1,
              centeredSlides: false
            }
          }
        })

        /**
         * Only if use arrow navigation
         * If is mobile, jump 1 in 1
         * Else, jump 4 in 4
         */
        $element.find('.timeline-prev, .timeline-next').on('click', function (e) {
          var prevDir   = e.target.classList.contains('timeline-prev')
          var nbSlides  = dateSlider.slides.length - 1
          var isMobile  = (dateSlider.width <= 768)
          var jump      = isMobile ? 1 : 4
          var slide     = dateSlider.activeIndex
          var nextSlide = prevDir ? (slide - jump <= 0 ? 0 : slide - jump) : (slide + jump <= nbSlides ? slide + jump : nbSlides)
          dateSlider.slideTo(nextSlide)
        })

        var issueSlider = new Swiper (syncIssues, {
          loop        : false,
          a11y        : true,
          initialSlide: initialSlide
        })

        dateSlider.params.control  = issueSlider;
        issueSlider.params.control = dateSlider;

      })
    }
  }

  function disableBtnIfNecessary ($element, swiper) {
    $prevBtn = $element.find('.timeline-prev')
    $nextBtn = $element.find('.timeline-next')
    if (swiper.activeIndex === 0) $prevBtn.addClass('disabled')
    else $prevBtn.removeClass('disabled')
    if (swiper.activeIndex === swiper.slides.length - 1) $nextBtn.addClass('disabled')
    else $nextBtn.removeClass('disabled')
  }

  $(document).ready(function() {
    initTimeline();
  })

})(window.jQuery, window, document);