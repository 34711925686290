(function($, window, document) {
    /* 3 par 3 */
    var page = 1; // commencer par la page 3
    var limit = 9; 

    $(document).on('click', '.loadmore', function(e) {
        e.preventDefault();
        if(page != 'end') {
            var $this = $(this),
                url = ajaxurl,
                action = $(this).data('action'),
                form_data = new FormData();


            form_data.append('action', action);
            form_data.append('page', page);

            $.ajax({
                url: url,
                dataType: 'json',
                cache: false,
                contentType: false,
                processData: false,
                data: form_data,
                type: 'POST',
            }).success(function(response) {
                // console.log(response);

                // affichage des nouveaux articles
                if (response.posts.length > 0) {
                    page = page + 1;

                    if (action == 'getArticle') {
                        var translate = $('.link-read').first().attr("title");
                        //console.log(translate);
                        $('#news_list').append(addPost(response.posts, response.svg, translate));
                    }

                }

                // on enleve le load
                if (response.posts.length < limit) {
                    page = 'end';
                    $('.loadmore').remove();
                }
 
            });
        }

    });

    function addPost(data, svg, translate) {
        var html = '';

        $.each(data, function(k, v) {
            html += '<div class="container-card picto-card">' +
                '<div class="wrapper-block">' +
                    '<div class="card white card-crop">' +
                        '<h5 class="card-category ' + v.cat_slug + '"><span class="content-category"> ' + v.cat_name + '</span></h5>' +
                        '<div class="picture-card">' +
                            '<img class="content-ratio" src="' + v.thumbnail + '">' +
                        '</div>' +
                        '<div class="card-content">' +
                            '<h2 class="title-card"><a href="' + v.link + '">' + v.title + '<a></h2>' +
                            '<p class="card-date">' + v.time + '</p>' +
                        '</div>' +
                        '<div class="card-footer align-right">' +
                            '<a href="' + v.link + '" title="'+ translate +'" class="link-read">'+ translate + ' ' + svg +'</a>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
            '</div>';
        });

        return html;

    }
})(window.jQuery, window, document);