/* -------------------------------------------------------------------------- */
/*                                   Form JS                                  */
/* -------------------------------------------------------------------------- */

/**
 * Moved to assets/scripts/modules/tools.js
window.initCaptcha = function () {
    if (document.getElementById("captcha")) {
        grecaptcha.render('captcha', {
            'sitekey' : document.getElementById('captcha').getAttribute('data-sitekey'),
            'hl'      : window.lang,
            'callback': callbackCaptcha
        })
    }

  if( $('.popin-survey').length > 0 ) {
    $('.popin-survey .g-recaptcha')
      grecaptcha.render('popin-survey-captcha', {
        'sitekey' : document.getElementById('popin-survey-captcha').getAttribute('data-sitekey'),
        'hl'      : window.lang,
        'callback': function() {
        $('.popin-survey-submit-btn button').attr('disabled', false);
        }
      });
  }
};
*/

window.callbackCaptcha = function (response) {
    var $input = jQuery('#captcha').siblings('.js-captcha')
    $input.val(response).closest('.js-form-check').parsley().validate('captcha')
};

/*
Parsley.addValidator('telephone', {
    requirementType: 'string',
    validateString: function (phone, requirement) {
        var $regex = new RegExp("^((\\+)"+requirement+"|0)[1-9]((\\.|\\-|\\||\\/?)\\d{2}){4}$", 'g');
        return $regex.test(phone)
    },
    messages: {
        en: 'It\'s not a valid phone number for this country.',
        fr: 'Ce n\'est pas un numéro de téléphone valide pour ce pays.'
        // en: 'It\'s not a valid phone number for this country, use +%s dialling code. ',
        // fr: 'Ce n\'est pas un numéro de téléphone valide, veuillez utiliser l\'indicatif +%s.'
    }
});
*/

(function($, window, document) {
    // Parsley JS
    var $forms = $('.js-form-check')

    $('#select_country').on('change', function() {
        var $indicatif = '[1-9][0-9]{0,2}'
        if($('#select_country option:selected').data('indicatif') !== ''){
            $indicatif = $('#select_country option:selected').data('indicatif')
        }
        //$('#phone').attr('data-parsley-telephone', $indicatif)
    })

    if ($forms){
        window.Parsley.setLocale(window.lang);

        $.each($forms, function (f, el) {
            var $form = $(el)

            $form.parsley({
                inputs: Parsley.options.inputs,
                excluded: 'input[type=button], input[type=submit], input[type=reset], [disabled]',
                errorClass: 'has-error',
                successClass: 'has-success',
                trigger: 'change blur',
                errorsContainer: function (field) {
                    var $field = $(field.$element)
                    if ($field.hasClass('form-group')) {
                        return $field
                    }
                    return $field.closest('.form-group')
                },
                classHandler: function (field) {
                    var $field = $(field.$element)
                    if ($field.hasClass('form-group')) {
                        return $field
                    }
                    return $field.closest('.form-group')
                },
                errorsWrapper: '<ul class="error-text parsley-errors-list"></ul>'
            })
        })
    }

    // Check if input has focus to maintain the label outside
    function checkFocus(input) {
        var customClass = 'input-custom-value'

        if (input.value) input.classList.add(customClass)
        else input.classList.remove(customClass)
    }

    function initLabelCustom () {
        var inputCustom = document.querySelectorAll('.js-input-custom')
        if (inputCustom) {
            Array.prototype.forEach.call(inputCustom, function(element, index){
                checkFocus(element)

                element.addEventListener('blur', function(event){
                    checkFocus(this)
                })
            })
        }
    }

    window.initRange = function () {
        var ranges = document.getElementsByClassName('js-custom-range')
        if (ranges) {
            Array.prototype.forEach.call(ranges, function(range, index) {
                var startValue = range.parentNode.querySelector('.js-custom-range-value').value || 1

                noUiSlider.create(range, {
                    start: startValue,
                    behaviour: 'tap-drag',
                    step: 1,
                    range: {
                        'min': 1,
                        'max': 4
                    },
                    pips: {
                        mode: 'positions',
                        values: [0, 100/3, 200/3, 100],
                        density: 4
                    }
                });

                range.noUiSlider.on('set', function(values, handle) {
                    var inputChanged = $(this.target).closest('[data-value]').attr('data-value', parseInt(values[0])).find('.js-custom-range-value').val(parseInt(values[0]))

                    if (inputChanged.length) {
                        inputChanged[0].dispatchEvent(new Event('change'))
                    }
                })

                var labels = range.parentNode.querySelectorAll('[data-setter]')
                if (labels) {
                    Array.prototype.forEach.call(labels, function(label, index) {
                        label.addEventListener('click', function() {
                            var index = this.getAttribute('data-setter')
                            range.noUiSlider.set([index])
                        })
                    })
                }
            })
        }
    }

    var files = document.getElementsByClassName('js-input-file')
    if (files) {
        Array.prototype.forEach.call(files, function(file, index) {
            file.addEventListener('change', function() {
                var container = $(this).closest('.js-file-wrapper').get(0)
                container.querySelector('.js-file-output').textContent = this.files.item(0).name
            })
        })
    }

    /* CONDTIONAL SELECT */
    var conditionalSelects = document.querySelectorAll('[data-conditional-select]')
    if (conditionalSelects) {
        Array.prototype.forEach.call(conditionalSelects, function(conditionalSelect, index){
            conditionalSelect.addEventListener('change', function(e){
                var selectToToggle = document.getElementById(this.getAttribute('data-conditional-select'))
                var triggerValue   = this.getAttribute('data-conditional-value')

                if (this.value === triggerValue) {
                    selectToToggle.classList.remove('hidden')
                    selectToToggle.querySelector('select').focus()
                    e.preventDefault();
                } else {
                    selectToToggle.classList.add('hidden')
                }
            })
        })
    }

    $(document).ready(function() {
        initLabelCustom()
    });

})(window.jQuery, window, document);
