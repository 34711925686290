/* -------------------------------------------------------------------------- */
/*                                   Menu JS                                  */
/* -------------------------------------------------------------------------- */

(function($, window, document) {
    var menuContainer = document.getElementById('menu-container')
    var menu          = document.getElementById('menu-main')
    var menuItems     = document.getElementsByClassName('menu-item-has-children')
    var togglerBtn    = document.getElementById('menu-toggler')
    var overlay       = document.getElementById('tool-overlay')
    // var toolbar       = document.querySelector('.sticky-tools-container')
    var toolbar       = document.querySelector('.tools-buttons-wrapper')



    function menuItemClick(e) {
        var menuItem = e.target.parentNode

        if (menuItem.classList.contains('open') && e.target.href) {
            window.location = e.target.href
        } else {
            /* CLOSE ALL SIBLINGS */
            var siblings = menuItem.parentNode.children
            Array.prototype.forEach.call(siblings, function(sibling, index){
                if (sibling.classList.contains('open') && menuItem !== sibling) {
                    sibling.classList.remove('open')
                }
            })
            menuItem.classList.add('open')
        }

        e.stopPropagation()
        e.preventDefault()
    }

    function togglerBtnClick(e) {
        e.target.classList.toggle('open')
        menuContainer.classList.toggle('open')
        document.documentElement.classList.toggle('no-scroll')
        document.body.classList.toggle('no-scroll')
        document.body.classList.toggle('no-scroll')

        document.ontouchmove = function(e){
            if (document.documentElement.classList.contains('no-scroll') && document.body.classList.contains('no-scroll')) {
                e.preventDefault()
            } else {
                return true
            }
        }

        overlay.classList.toggle('open')
        overlay.classList.toggle('menu')
        toolbar.classList.toggle('menu')
    }

    function resetMenu() {
        if (togglerBtn) togglerBtn.classList.remove('open')
        if (menuContainer) menuContainer.classList.remove('open')
        document.documentElement.classList.remove('no-scroll')
        document.body.classList.remove('no-scroll')

        document.ontouchmove = function(e){ return true }

        if (menuItems) {
            Array.prototype.forEach.call(menuItems, function(menuItem, index){
                // var link = menuItem.querySelector('a')
                var link = menuItem.firstChild

                if (menuItem.classList.contains('open')) menuItem.classList.remove('open')

                link.removeEventListener('click', menuItemClick)
            });
        }

        if (togglerBtn) {
            togglerBtn.classList.remove('open')
            togglerBtn.removeEventListener('click', togglerBtnClick)
        }

        overlay.classList.remove('open')
        overlay.classList.remove('menu')
        toolbar.classList.remove('menu')
    }

    function closeMenu() {
        if (togglerBtn) togglerBtn.classList.remove('open')
        if (menuContainer) menuContainer.classList.remove('open')
        document.documentElement.classList.remove('no-scroll')
        document.body.classList.remove('no-scroll')

        document.ontouchmove = function(e){ return true }

        if (togglerBtn) { togglerBtn.classList.remove('open') }

        overlay.classList.remove('open')
        overlay.classList.remove('menu')
        toolbar.classList.remove('menu')
    }

    function initMenu() {
        if ( $(window).width() < 1024 ) {
            if (menuItems) {
                Array.prototype.forEach.call(menuItems, function(menuItem, index){
                    // var link = menuItem.querySelector('a')
                    var link = menuItem.firstChild

                    link.addEventListener('click', menuItemClick)
                });
            }

            if (togglerBtn) togglerBtn.addEventListener('click', togglerBtnClick)

        } else resetMenu()

        if (overlay) { overlay.addEventListener('click', closeMenu) }
    }

    initMenu()

    window.addEventListener('resize', initMenu)
    window.addEventListener('orientationchange', initMenu)

})(window.jQuery, window, document);


jQuery(document).ready(function($){
    if($('#menu-main li.disable').hasClass('disable')){
        $('#menu-main li.disable > a').contents().unwrap().wrap('<span>');
     }
});