(function($, window, document) {

  $('.contact-form form').on('submit', function(e) {
    e.preventDefault();

    var $this      = $(this),
    formdata   = (window.FormData) ? new FormData($this[0]) : null,
    url        = ajaxurl,
    action     = $this.attr('action'),
    country    = $this.find('[name="select_country"]').val(),
    country_id = $this.find('option[value="' + country + '"]').data('id'),
    data       = (formdata !== null) ? formdata : $this.serialize(),
    $error     = $('.js-error'),
    $confirm   = $('.js-confirm');

    data.append('action', action );
    data.append('select_country', country_id);
    $this.find('.error').remove();

    // disabled button
    $this.find('button').prop('disabled', true);

    $.ajax({
      url: url,
      type: 'POST',
      data: data,
      cache : false,
      contentType: false,
      processData: false,
      dataType: 'json',
      success : function(res){
        if (res.success) {
          if (res.data.message && res.data.interne && res.data.externe) {
            // $this.append('')
            $this.remove()
              $error.hide()
              $confirm.show()

          } else if(res.data.message && !res.data.interne && !res.data.externe) {
            $error.show()
              $('html, body').animate({scrollTop:$error.position().top}, 'slow');
          }
        }
        else {
          // if error, enable button
          $this.find('button').prop('disabled', false);
        }
      }
    });

    return false;
  });

  if($('#sendMessage').length == 1) {
    $('#select_country').change(function() {
      $('.select_departement').val('');
      $('.select_departement').find('option').attr('selected', false);

      setTimeout(function() {
        if( $('#department').css('display') != "none" && $('.select_departement').val() == "" ) {
          $('#sendMessage .js-submit').attr('disabled', true);
        } else {
          $('#sendMessage .js-submit').attr('disabled', false);
        }
      }, 800);
    });
    $('.select_departement').change(function() {
      setTimeout(function() {
        if( $('#department').css('display') != "none" && $('.select_departement').val() != "" ) {
          $('#sendMessage .js-submit').attr('disabled', false);
        } else {
          $('#sendMessage .js-submit').attr('disabled', true);
        }
      }, 800);
    })
  }


  $('#request').on('change', function(e) {
    e.preventDefault();

    var $this = $(this),
    val   = $this.val(),
    rgpd  = $this.find('option[value="' + val + '"]').data('select-rgpd');

    $('#message').attr('placeholder', window.placeholderMessage[$this.find('option:selected').attr('data-post-name')]);

    $('.wrapper-rgpd label').addClass('hidden');
    $('.wrapper-rgpd label[data-rgpd=' + rgpd + ']').removeClass('hidden');

  });

  $('.tool-sales-country, .tool-sales-department').on('change', function(e) {
    e.preventDefault();
    var $this = $(this),
    url = ajaxurl,
    val = $this.val(),
    link = $this.find('option[value="' + val + '"]').data('link');

    if (val !== 'fr') {
      window.location.href = link;
    }

  });

})(window.jQuery, window, document);
