// Validation errors messages for Parsley
// Load this after Parsley

Parsley.addMessages('ms', {
  dateiso:  "Nilai hendaklah berbentuk tarikh yang sah (YYYY-MM-DD).",
  minwords: "Ayat terlalu pendek. Ianya perlu sekurang-kurangnya %s patah perkataan.",
  maxwords: "Ayat terlalu panjang. Ianya tidak boleh melebihi %s patah perkataan.",
  words:    "Panjang ayat tidak sah. Jumlah perkataan adalah diantara %s hingga %s patah perkataan.",
  gt:       "Nilai lebih besar diperlukan.",
  gte:      "Nilai hendaklah lebih besar atau sama.",
  lt:       "Nilai lebih kecil diperlukan.",
  lte:      "Nilai hendaklah lebih kecil atau sama."
});
