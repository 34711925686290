// This is included with the Parsley library itself,
// thus there is no use in adding it to your project.


Parsley.addMessages('ur', {
  defaultMessage: "شائد یہ قیمت غلط ہے۔",
  type: {
    email:        "یہ قیمت ایک درست ای میل ہونی چاہیے۔",
    url:          "یہ قیمت ایک درست یو آر ایل ہونا چاہیے۔ ",
    number:       "یہ قیمت ایک درست نمبر ہونا چاہیے۔",
    integer:      "یہ قیمت ایک عدد صحیح ہونا چاہیے۔",
    digits:       "یہ قیمت اعداد ہونے چاہیے۔",
    alphanum:     "یہ قیمت حرفی ہندسی میں سے ہونا چاہیے۔"
  },
  notblank:       "یہ قیمت خالی نہیں ہونی چاہیے۔",
  required:       "یہ قیمت ضروری ہے۔ ",
  pattern:        "شائد یہ قیمت غلط ہے۔",
  min:            "یہ قیمت %s سے بڑی یا اسکے برابر ہونی چاہیے۔",
  max:            "یہ قیمت %sسے چھوٹی یا اسکے برابر ہونی چاہیے۔",
  range:          "یہ قیمت %sاور %s کے درمیان ہونی چاہیے۔",
  minlength:      "یہ قیمت بہت کم احراف پر مشتمل ہے۔ یہ %sاحراف یا اس سے زیادہ ہونے چاہیے۔",
  maxlength:      "یہ قیمت بہت زیادہ احراف پر مشتمل ہے۔ یہ %sاحراف یا اس سے کم ہونے چاہیے۔",
  length:         "اس قیت کا طول غلط ہے۔ یہ %s اور %s احراف پر مشتمل ہونی چاہیے۔",
  mincheck:       "آپکو کم سے کم %s قیمتوں کا انتخاب کرنا ہوگا۔",
  maxcheck:       "آپکو %s یا اس سے کم قیمتوں کا انتخاب کرنا ہوگا۔",
  check:          "آپکو %s اور %s کے درمیان کسی قیمت کا انتخاب کرنا ہوگا۔",
  equalto:        "یہ قیت جیسی ہے ویسی ہی رہنی چاہیے۔ "
});

Parsley.setLocale('ur');
