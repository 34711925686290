/* -------------------------------------------------------------------------- */
/*                                  Toolbar                                   */
/* -------------------------------------------------------------------------- */

(function($, window, document) {
    var toolButtons = document.getElementsByClassName('js-tool-button')
    var toolOverlay = document.getElementById('tool-overlay')
    var pageContainer = document.getElementById('page-container')

    if (toolButtons) {
        Array.prototype.forEach.call(toolButtons, function(toolButton, index){
            toolButton.addEventListener('click', function(e) {
                var that = this

                this.classList.toggle('open')

                if (this.classList.contains('open')) {
                    Array.prototype.forEach.call(toolButtons, function(toolButton, index){
                        if (that != toolButton && toolButton.parentNode.classList.contains('open')) {
                            toolButton.parentNode.classList.remove('open')
                            toolButton.classList.remove('open')
                        }
                    })
                    this.parentNode.classList.add('open')

                    if (toolOverlay) toolOverlay.classList.add('open')

                    if (pageContainer) pageContainer.classList.add('no-overflow-x')

                    document.documentElement.classList.add('no-scroll')
                    document.body.classList.add('no-scroll')
                    document.ontouchmove = function(e){ e.preventDefault() }
                } else {
                    toolOverlay.click()
                }

                e.stopPropagation()
            })
        })

        var toolCloseButtons = document.getElementsByClassName('js-tool-close')
        if (toolCloseButtons) {
            Array.prototype.forEach.call(toolCloseButtons, function(toolCloseButton, index){
                toolCloseButton.addEventListener('click', function(e) {
                    if (this.parentNode.parentNode.classList.contains('open')) this.parentNode.parentNode.classList.remove('open')
                    if (toolOverlay) toolOverlay.classList.remove('open')
                    if (pageContainer) pageContainer.classList.remove('no-overflow-x')

                    Array.prototype.forEach.call(toolButtons, function(toolButton, index){
                        toolButton.classList.remove('open')
                        toolButton.blur()
                    })

                    document.documentElement.classList.remove('no-scroll')
                    document.body.classList.remove('no-scroll')
                    document.ontouchmove = function(e){ return true }
                })
            })
        }

        if (toolOverlay) {
            toolOverlay.addEventListener('click', function() {
                this.classList.remove('open')

                Array.prototype.forEach.call(toolButtons, function(toolButton, index){
                    if (toolButton.parentNode.classList.contains('open')) toolButton.parentNode.classList.remove('open')
                    toolButton.classList.remove('open')
                    toolButton.blur()
                })

                if (pageContainer) pageContainer.classList.remove('no-overflow-x')

                document.documentElement.classList.remove('no-scroll')
                document.body.classList.remove('no-scroll')
                document.ontouchmove = function(e){ return true }
            })
        }
    }

    var initContactBtn = function() {
        if( $(window).scrollTop() > 800 ) {
            $('body').addClass('backToTopDeployed');
        } else {
            $('body').removeClass('backToTopDeployed');
        }
    }

    if( $('#adStickyButtons').length > 0 ) {
        initContactBtn();
        $(window).on('scroll', function() {
            initContactBtn();
        });
    }

    if( $('#backToTop').length > 0 ) {
        $('#backToTop').on('click tap', function(e) {
            e.preventDefault();
            $('html, body').animate( {scrollTop: 0}, 600 );
        });
    }

})(window.jQuery, window, document);
