/* -------------------------------------------------------------------------- */
/*                                  Tools JS                                  */
/* -------------------------------------------------------------------------- */

(function($, window, document) {

  var backToTop = document.getElementById('back-to-top');

  if (backToTop) {
    backToTop.addEventListener('click', function(e) {
      $('html, body').animate({
        scrollTop: 0
      }, 'slow');

      e.preventDefault();
      return false;
    });
  }

  function setCookie(name,value,days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }
  function eraseCookie(name) {
    document.cookie = name+'=; Max-Age=-99999999;';
  }

  window.initCaptcha = function () {
    if (document.getElementById("captcha")) {
      grecaptcha.render('captcha', {
        'sitekey' : document.getElementById('captcha').getAttribute('data-sitekey'),
        'hl'      : window.lang,
        'callback': callbackCaptcha
      });
    }

    /* OLD CODE FOR SURVEY POPIN
    if( $('.popin-survey').length > 0 ) {
      if( getCookie('popinsurvey') == null ) {
        /** if cookie is not set **/

        /** wait for a few secs **//*
        setTimeout(function() {
          jQuery('.popin-survey').slideDown();
        }, 10000);

        /** recaptcha **//*
        grecaptcha.render('popin-survey-captcha', {
          'sitekey' : document.getElementById('popin-survey-captcha').getAttribute('data-sitekey'),
          'hl'      : window.lang,
          'callback': function() {
            $('.popin-survey-submit-btn button').attr('disabled', false);
            $('.popin-survey-submit-btn button').on('click tap', function(e) {
              e.preventDefault();

              /** add cookie for 1 day **//*
              setCookie('popinsurvey', 'ad_popin_survey', 1);
              window.open($('.popin-survey-submit-btn button').attr('data-href'), '_blank');
              jQuery('.popin-survey').slideUp();
            });
          }
        });

        /** close modal **//*
        $('.popin-survey-close').on('click tap', function(e) {
          e.preventDefault();

          jQuery('.popin-survey').slideUp(function() {
            /** add cookie for xx days **//*
            setCookie('popinsurvey', 'ad_popin_survey', 1);
          });
        });
      }
    }
    */
  };

})(window.jQuery, window, document);
