/* -------------------------------------------------------------------------- */
/*                                   Slider                                   */
/* -------------------------------------------------------------------------- */

(function($, window, document) {

    $('.js-slider').each(function(){
        var $slider         = $(this)
        var loop            = $slider.data('loop') ? true : false
        var disableAutoPlay = $slider.data('delay') ? false : true
        var delayTimeout    = $slider.data('delay') ? $slider.data('delay') : 3000

        new Swiper ($slider, {
            slidesPerView               : 1,
            uniqueNavElements           : false,
            pagination                  : '.swiper-pagination',
            loop                        : loop,
            autoHeight                  : true,
            autoplayDisableOnInteraction: disableAutoPlay,
            autoplay                    : delayTimeout
        });
    })

})(window.jQuery, window, document);