/* -------------------------------------------------------------------------- */
/*                                  Modal JS                                  */
/* -------------------------------------------------------------------------- */

(function($, window, document) {
    window.initModal = function () {
        var overlay = document.getElementById('overlay')
        if (overlay) {
            overlay.addEventListener('click', function() {
                var that = this
                var elementsToDismiss = document.querySelectorAll('[role="dialog"]')
                Array.prototype.forEach.call(elementsToDismiss, function(elementToDismiss, index){
                    that.classList.remove('open')
                    elementToDismiss.classList.remove('open')
                })
            })
        }


        var togglers = document.querySelectorAll('[data-toggler]')
        if (togglers) {
            Array.prototype.forEach.call(togglers, function(toggler, index){
                toggler.addEventListener('click', function(e) {
                    var target = document.getElementById(this.getAttribute('data-target'))

                    target.classList.add('open')
                    overlay.classList.add('open')

                    e.stopPropagation()
                })
            })
        }

        var dissmissElements = document.querySelectorAll('[data-dismiss]')
        if (dissmissElements) {
            Array.prototype.forEach.call(dissmissElements, function(dismiss, index){
                dismiss.addEventListener('click', function(e){
                    var target = document.getElementById(this.getAttribute('data-dismiss'))
                    target.classList.remove('open')
                    overlay.classList.remove('open')
                    e.stopPropagation()
                })
            })
        }
    }

    $(document).ready(function() {
        window.initModal();
    });

    if( $('.iframe-open-in-popin').length > 0 ) {
        console.log($('.iframe-open-in-popin .iframe-cta').parents('.card').find('iframe').attr('src'));
        $('.iframe-open-in-popin .iframe-cta').each(function(i, d) {
            $(d).on('click tap', function(e) {
                e.preventDefault();

                var cardHtml = $(this).parents('.card').html();
                var cardTitle = "<div class='sidebar-title'>" + $(this).parents('.sidebar-block').find('.sidebar-title').text() + "</div>";

                var modal = "<div class='overlay' style='display: block;'></div><div class='modal open modal-iframe-video'>";
                modal += '<button type="button" class="modal-close tool-close js-tool-close"><span class="line1"></span><span class="line3"></span></button>';
                modal += "<div class='modal-content'>"
                modal += "<div class='modal-title'>" + cardTitle + "</div>";
                modal += cardHtml;
                modal += "</div>";
                modal += "</div>";
                $('body').append(modal);
                $('.modal-iframe-video .iframe-cta').remove();
                $('.modal-iframe-video iframe').css('height', $('.modal-iframe-video iframe').innerWidth()*0.5647);
                var videoSrc = new URL($('.modal-iframe-video iframe').attr('src'));
                var src_params = videoSrc.searchParams;
                src_params.append('autoplay', '1');
                videoSrc.search = src_params.toString();

                $('.modal-iframe-video iframe').attr('src', videoSrc.toString());
                $('.modal-iframe-video iframe').attr('allow', 'autoplay');

                $('.modal-iframe-video .modal-close').on('click tap', function(e) {
                    e.preventDefault();
                    $('.modal-iframe-video, .overlay').fadeOut(function() {
                        $('.modal-iframe-video, .overlay').remove();
                    });
                });
            });
        });
    }
})(window.jQuery, window, document);
