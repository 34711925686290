(function($, window, document) {

    $(window.document).on('click', '[data-favoris]', function(e) {

        e.preventDefault();

        var $this     = $(this),
            id        = $this.data('id'),
            title     = $this.data('title'),
            slug      = $this.data('slug'),
            file      = $this.data('file'),
            post_type = $this.data('post-type'),
            data      = {
                id: id,
                title: title,
                slug: slug,
                file: file,
                post_type: post_type
            },
            uniqkey   = id + '' + slug,
            favorites = readCookie('favorites');

        if (favorites) {
            favorites = JSON.parse(favorites);
        } else {
            favorites = {};
        }

        if (typeof favorites == 'object') {
            if (uniqkey in favorites) {
                delete favorites[uniqkey];
            } else {
                favorites[uniqkey] = {
                    'id': id,
                    'slug': slug,
                    'file': file,
                    'post_type': post_type
                }
            }
        } else {
            favorites[uniqkey] = {
                'id': id,
                'slug': slug,
                'file': file,
                'post_type': post_type
            }
        }

        if (favorites != null) {
            createCookie('favorites', JSON.stringify(favorites));
        }


        if ($this.hasClass('delete-fav')) {

            $('[data-fav-id="' + id + '"]').remove();
            $this.removeClass('on delete-fav');
            $('.share-box button.delete-fav[data-id="' + id + '"]').removeClass('delete-fav on');
            $('[data-token="' + id + '"]').removeClass('delete-fav on');

            if (isEmpty(favorites)) {
                $('.no-fav.hidden').removeClass('hidden');
                $('.download-all-files ').addClass('hidden');
            } else {

                $.each(favorites, function(index, value) {
                    if(value.file != undefined) {
                        $('.download-all-files ').removeClass('hidden');
                        return false;
                    } else {
                        $('.download-all-files ').addClass('hidden');
                    }
                });
            }

        } else {

            addFavorite(data);
            $this.addClass('on delete-fav');
            $('.no-fav').addClass('hidden');

            $.each(favorites, function(index, value) {
                if(value.file != undefined) {
                    $('.download-all-files ').removeClass('hidden');
                    return false;
                } else {
                    $('.download-all-files ').addClass('hidden');
                }
            });
        }

        return true;
    });

    $(window.document).on('click', '[data-favoris-accordion]', function(e) {

        e.preventDefault();

        var $this     = $(this),
            token     = $this.data('token'),
            title     = $this.data('title'),
            slug      = $this.data('slug'),
            file      = $this.data('file'),
            page_id   = $this.data('page-id'),
            post_type = $this.data('post-type'),
            data      = {
                token: token,
                slug: slug,
                title: title,
                page_id: page_id,
                file: file,
                post_type: post_type
            },
            uniqkey   = token + '' + slug,
            favorites = readCookie('favorites');

        if (favorites) {
            favorites = JSON.parse(favorites);
        } else {
            favorites = {};
        }

        if (typeof favorites == 'object') {
            if (uniqkey in favorites) {
                delete favorites[uniqkey];
            } else {
                favorites[uniqkey] = {
                    'token': token,
                    'title': title,
                    'slug': slug,
                    'page_id': page_id,
                    'file': file,
                    'post_type': post_type
                }
            }
        } else {
            favorites[uniqkey] = {
                'token': token,
                'title': title,
                'slug': slug,
                'page_id': page_id,
                'file': file,
                'post_type': post_type
            }
        }

        if (favorites != null) {
            createCookie('favorites', JSON.stringify(favorites));
        }

        addFavorite(data);
        $this.addClass('on delete-fav');
        $('.no-fav').addClass('hidden');

        $.each(favorites, function(index, value) {

            if(value.file != undefined) {
                $('.download-all-files').removeClass('hidden');
            } else {
                $('.download-all-files').addClass('hidden');
            }

        });

        return true;
    });

function createCookie(name, value, day) {
	// Le nombre de jours est spécifié
    if (day) {
		var date = new Date();
        // Converti le nombre de jour en millisecondes
		date.setTime(date.getTime()+(day*24*60*60*1000));
		var expire = "; expire="+date.toGMTString();
	}
    // Aucune valeur de jours spécifiée
	else var expire = "";
	document.cookie = name + "=" + value + expire + "; path=/";
}

function readCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i = 0;i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1,c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	}
	return null;
}

function isEmpty(obj) {

    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0)    return false;
    if (obj.length === 0)  return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}

function addFavorite(data) {
    if (typeof data != undefined) {
        var id = data.id || data.token;

        var html = "<li class='fav-item' data-fav-id='" + id + "'>";
        html += "<div class='fav-item-title'>" + data.title + "</div>";
        html += "<div class='fav-controls'>";
        if (data.file != undefined) {
            html += "<a href='" + data.file + "' class='download-pdf' target='_blank'>";
            html += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 466.67 533.33" class="icon"><path class="icon-pdf" d="M405.55 307c-7.11-7-22.87-10.71-46.86-11-16.24-.18-35.78 1.25-56.34 4.13-9.2-5.31-18.69-11.09-26.14-18.05-20-18.71-36.76-44.67-47.17-73.23.68-2.67 1.26-5 1.8-7.4 0 0 11.28-64.09 8.3-85.76a25 25 0 0 0-1.46-6.14l-1-2.52c-3.07-7.08-9.09-14.58-18.52-14.17l-5.53-.18h-.15c-10.52 0-19.1 5.38-21.35 13.42-6.84 25.23.22 63 13 111.84l-3.27 8c-9.16 22.33-20.64 44.82-30.77 64.67l-1.32 2.58c-10.66 20.85-20.32 38.56-29.09 53.55l-9.05 4.79c-.66.35-16.17 8.55-19.81 10.75-30.82 18.38-51.3 39.3-54.69 55.9-1.08 5.3-.28 12.08 5.22 15.21l8.75 4.4a26.37 26.37 0 0 0 11.9 2.87c22 0 47.5-27.38 82.65-88.73A823.32 823.32 0 0 1 292 321.68c30.86 17.38 68.82 29.45 92.78 29.45a43.37 43.37 0 0 0 10.9-1.19 18.54 18.54 0 0 0 10.83-7.39c4.65-7 5.59-16.63 4.33-26.5-.42-2.94-2.76-6.56-5.29-9.05zM77.23 424c4-11 19.88-32.63 43.33-51.85 1.48-1.2 5.11-4.6 8.43-7.76C104.47 403.5 88 419.09 77.23 424zm139-320c7.07 0 11.08 17.81 11.42 34.51s-3.57 28.42-8.42 37.09c-4-12.84-6-33.07-6-46.3.01-.01-.29-25.3 2.95-25.3zm-41.49 228c4.92-8.81 10-18.1 15.28-28a532.43 532.43 0 0 0 26.81-58.51c11.93 21.71 26.79 40.17 44.26 55q3.27 2.77 6.91 5.55c-35.53 7.05-66.23 15.6-93.26 25.96zm224-2c-2.16 1.35-8.36 2.14-12.35 2.14-12.87 0-28.79-5.88-51.11-15.45 8.58-.64 16.44-1 23.49-1 12.91 0 16.73-.06 29.35 3.16s12.77 9.82 10.6 11.15zm38.82-226.16l-74.43-74.38C346.92 13.26 314.92 0 292 0H42A41.79 41.79 0 0 0 .33 41.67v450A41.79 41.79 0 0 0 42 533.33h383.33A41.79 41.79 0 0 0 467 491.67V175c0-22.92-13.26-54.93-29.46-71.13zM414 127.44a44 44 0 0 1 4.83 5.89h-85.16V48.2a44 44 0 0 1 5.89 4.8zm19.7 364.23a8.44 8.44 0 0 1-8.33 8.33H42a8.44 8.44 0 0 1-8.33-8.33v-450A8.45 8.45 0 0 1 42 33.33h250a45.42 45.42 0 0 1 8.33.89v132.45h132.45a45.41 45.41 0 0 1 .89 8.33z"></path></svg>';
            html += "</a>";
        }
        html += "<button type='button' class='remove-fav delete-fav' data-id='" + id + "' data-post-type='" + data.post_type +"' data-favoris='true' data-slug='" + data.slug + "' >";
        html += "<span class='line1'></span><span class='line3'></span>";
        html += "</button>";
        html += "</div>";
        html += "</li>";

        $('.tools-buttons-wrapper .tool ul.fav-items').append(html);
    }
}

})(window.jQuery, window, document);