(function($, window, document) {
    var page = 1;

    $(document).on('change', '.filter-contact', function(e) {
        e.preventDefault();
        var $this = $(this),
            url = ajaxurl,
            filter_country = $this.val(),
            country_id = $this.find('option[value="' + filter_country + '"]').data('id'),
            action = 'filterContact',
            form_data = new FormData();

        $('#contact_list > .container-card').fadeOut(300, function() {
            $(this).remove();
        });

        if(filter_country == 'fr') {
            $('.filter-contact-dep').val('');
        }

        if(filter_country !== '' && filter_country !== 'fr') {

            form_data.append('action', action);
            form_data.append('country_id', country_id);

            $.ajax({
                url: url,
                dataType: 'json',
                cache: false,
                contentType: false,
                processData: false,
                data: form_data,
                type: 'POST',
            }).success(function(response) {

                // affichage des nouveaux articles
                if (response.data.success) {
                    if (response.data.posts.length > 0) {
                        $('#contact_list').append(addContact(response.data.posts, response.data.svg)).fadeIn('slow');
                    }
                }

            });

        }
    });

    $(document).on('change', '.filter-contact-dep', function(e) {
        e.preventDefault();
        var $this      = $(this),
            url        = ajaxurl,
            filter_dep = $this.val(),
            action     = 'filterContactDep',
            form_data  = new FormData();

        $('#contact_list > .container-card').fadeOut(300, function() {
            $(this).remove();
        });
        
        if(filter_dep !== '') {

            form_data.append('action', action);
            form_data.append('filter_dep', filter_dep);

            $.ajax({ 
                url: url,
                dataType: 'json',
                cache: false,
                contentType: false,
                processData: false,
                data: form_data,
                type: 'POST',
            }).success(function(response) {

                // affichage des nouveaux articles
                if (response.data.success) {
                    if (response.data.posts.length > 0) {
                        $('#contact_list').append(addContact(response.data.posts, response.data.svg)).fadeIn('slow');
                    }
                }

            });
        }

    });


    function addContact(data, svg) {
        var html = '';

        $.each(data, function(k, v) {
            html += '<div class="container-card full small-margin">' +
                    '<div class="card-content">' +
                        '<h3 class="map-title black">'+ v.firstname + ' ' + v.lastname + '</h3>' +

                        '<div class="infobox-content card-description">' +
                            '<div class="column">';

            if (v.picture !== null) {
                html += '<div><img src="'+ v.picture.url + '"></div>';
            }

            html += '</div>' +
                    '<div class="column">' +
                        '<div>' +
                            '<h4 class="map-subtitle">Activities</h4>' +
                            '<p>';

            if (v.activities !== null) {
                $.each(v.activities, function(k1, v1) {
                    html += v1.activity + '<br>';
                });
            }

            html += '</p>' +
                        '</div>' +
                    '</div>' +
                    '<div class="column">' +
                        '<div>' +
                            '<h4 class="map-subtitle">Phone</h4>' +
                            '<p><a href="tel:'+ v.phone + '">'+ v.phone + '</a></p>' +
                        '</div>' +
                        '<div>' +
                            '<a href="mailto:'+ v.email + '&subject=I have a sales or technical request" class="button with-svg red">' +
                                'Contact me' +
                                svg +
                            '</a>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
            '</div>'+
        '</div>';
        });

        return html;

    }
})(window.jQuery, window, document);