/* -------------------------------------------------------------------------- */
/*                            Tranversal navigation                           */
/* -------------------------------------------------------------------------- */

(function($, window, document) {

    var transNav = document.getElementById('trans-nav')
    if (transNav) {
        var blocs = transNav.querySelectorAll('[data-trans-bloc]')

        function createControlTimeline(bloc) {
            var title    = bloc.querySelector('[data-trans-bloc-title]').textContent
            var controls = transNav.querySelector('[data-trans-controls]')
            var control  = document.createElement("a")
            control.textContent = title
            control.setAttribute('href', '#' + bloc.id)
            control.setAttribute('data-trans-control', '')
            controls.appendChild(control)
        }

        function scrollToIndex(index) {
            $('html, body').animate({
                scrollTop: $(blocs[index]).offset().top
            }, 'slow');
        }

        if (blocs) {
            Array.prototype.forEach.call(blocs, function(bloc, index){
                createControlTimeline(bloc)
            })
        }

        var buttons = transNav.querySelectorAll('[data-trans-control]')
        if (buttons) {
            Array.prototype.forEach.call(buttons, function(button, index){
                button.addEventListener('click', function() {
                    Array.prototype.forEach.call(buttons, function(button, index){
                        button.classList.remove('active')
                    })
                    var index = Array.prototype.indexOf.call(this.parentNode.children, this)
                    scrollToIndex(index)
                    button.classList.add('active')
                })
            })
        }
    }

})(window.jQuery, window, document);