/* -------------------------------------------------------------------------- */
/*                                  Toggle JS                                 */
/* -------------------------------------------------------------------------- */

(function($, window, document) {

    // var togglers = document.querySelectorAll('[data-toggle]')
    // if (togglers) {
    //     Array.prototype.forEach.call(togglers, function(toggler, index){
    //         toggler.addEventListener('click', function(e) {
    //             var classToToggle = this.getAttribute('data-toggle')

    //             var valueFav = this.getAttribute('data-id')

    //             this.classList.toggle(classToToggle)

    //             // if (hasClass(this, 'on')) {
    //             //     if (getCookie('favorites') != null) {
    //             //         // objectFav.valueFav = valueFav

    //             //         // console.log(objectFav)
    //             //         console.log(getCookie('favorites'))

    //             //         pushInCookie()
    //             //     } else {
    //             //         var objectFav = {}
    //             //         objectFav.valueFav = valueFav

    //             //         console.log('pouet')
    //             //         setCookie('favorites', objectFav)
    //             //     }
    //             // } else {
    //             //     removeInCookie()
    //             // }

    //             e.stopPropagation()
    //         })
    //     })
    // }

    function setCookie(sName, sValue) {
        var today = new Date(), expires = new Date();

        expires.setTime(today.getTime() + (365*24*60*60*1000));

        document.cookie = sName + "=" + sValue + ";expires=" + expires.toGMTString();
    }

    function getCookie(sName) {
        var cookContent = document.cookie, cookEnd, i, j;
        var sName = sName + "=";

        for (i=0, c=cookContent.length; i<c; i++) {
            j = i + sName.length;
            if (cookContent.substring(i, j) == sName) {
                cookEnd = cookContent.indexOf(";", j);
                if (cookEnd == -1) {
                    cookEnd = cookContent.length;
                }
                return cookContent.substring(j, cookEnd);
            }
        }

        return null;
    }

    function ereaseCookie () {
        createCookie('favorites', '', -1);
    }

    function pushInCookie () {}

    function removeInCookie () {}

    function hasClass(element, cls) {
        return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
    }

})(window.jQuery, window, document);