/* -------------------------------------------------------------------------- */
/*                            Iframe Auto height JS                           */
/* -------------------------------------------------------------------------- */

(function($, window, document) {

  function resizeIframe() {
    var iframeSize = document.querySelectorAll('.js-iframe-size')

    if (iframeSize) {
      Array.prototype.forEach.call(iframeSize, function(element, index) {
        console.log('click');
        console.log('element.contentWindow.document.body.scrollHeight');
        element.style.height = element.contentWindow.document.body.scrollHeight + 'px'
      })
    }
  }

  $(document).ready(function() {
    resizeIframe();
  });

})(window.jQuery, window, document);
